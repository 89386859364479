import React, { useEffect } from "react";
import { Link } from "@reach/router";
import { navigate } from "gatsby";
import DotaznikLayout from "../../../components/nabidka/dotaznik/DotaznikLayout";



const UzZemrel = ({userData, updateData}) => {
  let uprava = userData;

  function odpoved(jak) {
    uprava.uzZemrel = {
      "otazka": "V jakém stavu je teď váš blízký?",
      "odpoved": jak
    }

    updateData(uprava);

    if (jak === "Po smrti") {
      navigate("/nabidka/dotaznik/co-nejrychleji/");
    }

    else {
      navigate("/nabidka/dotaznik/region/");
    }
  }



  return (
    <DotaznikLayout phase={1} progress={60} isSupp>
      <h1>Jakou situaci řešíte?</h1>
      <p>Dejte nám vědět, v jakém stavu je váš blízký.</p>

      <button onClick={() => odpoved("Po smrti")}>Blízký je po smrti</button>
      <button onClick={() => odpoved("Brzy zemře")}>Chceme se připravit</button>

      <Link to="/nabidka/dotaznik/" className="zpet">← Zpět</Link>
    </DotaznikLayout>
  );
};


export default UzZemrel;